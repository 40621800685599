import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Popper from '@material-ui/core/Popper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import isToday from 'date-fns/isToday';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { formatDateTime } from '../../utils';
import Button from '../common/Button';

const useStyles = makeStyles(
    (theme) => ({
        popper: {
            zIndex: 999,
            '&[x-placement*="bottom"] $arrow': {
                top: 0,
                left: 0,
                marginTop: '-0.9em',
                width: '3em',
                height: '1em',
                '&::before': {
                    borderWidth: '0 1em 1em 1em',
                    borderColor: `transparent transparent ${theme.palette.neutral.hudBackground} transparent`,
                },
            },
        },
        arrow: {
            position: 'absolute',
            fontSize: 7,
            width: '3em',
            height: '3em',
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: 0,
                height: 0,
                borderStyle: 'solid',
            },
        },
        motivoWrapper: {
            background: 'black',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            padding: theme.spacing(1),
            borderRadius: 4,
            minWidth: 360,
        },
        motivoInput: {
            background: 'white',
            borderRadius: 4,
            fontSize: 13,
        },
    }),
    { name: 'RevisionFichajes' },
);
export default function FichajeEditMotivoRevisionTooltip({ fichaje, onUpdate }) {
    const classes = useStyles();
    const [value, setValue] = useState(fichaje.motivo ?? '');
    const [arrowRef, setArrowRef] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const contextMenuOpen = Boolean(anchorEl);
    const contextMenuId = contextMenuOpen ? 'simple-popover' : undefined;

    const isEditable = !isToday(new Date(fichaje.fecha));

    return (
        <>
            <Popper
                id={contextMenuId}
                open={contextMenuOpen}
                anchorEl={anchorEl}
                role={undefined}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                    },
                    arrow: {
                        enabled: true,
                        element: arrowRef,
                    },
                    offset: {
                        enabled: true,
                        offset: '0, 10',
                    },
                }}
                placement='bottom'
                className={clsx(classes.popper)}
            >
                <ClickAwayListener
                    onClickAway={(ev) => {
                        if (document.getElementById('root').contains(ev.target)) setAnchorEl(null);
                    }}
                >
                    <div className={classes.motivoWrapper}>
                        <span className={classes.arrow} ref={setArrowRef} />
                        {fichaje.solicitud_revision && (
                            <Typography variant='body2'>
                                El empleado solicitó revision el {formatDateTime(fichaje.solicitud_revision.timestamp)}
                            </Typography>
                        )}
                        <Input
                            fullWidth
                            className={classes.motivoInput}
                            multiline
                            minRows={3}
                            placeholder='Ejemplo: El operario se olvido fichar a las 16:00'
                            value={value}
                            disabled={!isEditable}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        {isEditable ? (
                            <Button
                                color='info'
                                fullWidth
                                size='big'
                                onClick={() => {
                                    setAnchorEl(null);
                                    return onUpdate(value);
                                }}
                            >
                                Guardar motivo
                            </Button>
                        ) : (
                            <Typography variant='subtitle1'>Este fichaje no se puede editar hasta mañana.</Typography>
                        )}
                    </div>
                </ClickAwayListener>
            </Popper>
            <IconButton
                className={classes.iconWithTooltip}
                onClick={(ev) => setAnchorEl((target) => (target ? null : ev.currentTarget))}
            >
                <WarningIcon />
            </IconButton>
        </>
    );
}

FichajeEditMotivoRevisionTooltip.propTypes = {
    fichaje: PropTypes.object,
    onUpdate: PropTypes.func,
};
