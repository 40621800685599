import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { companiesProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogEditor from '../../common/forms/DialogEditor';

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        searchIcon: {
            fontSize: 20,
            color: theme.palette.neutral.primary,
        },
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'ConfigConvenioDialog' },
);

const ConfigConvenioSchema = Yup.object().shape({
    horas_laborables: Yup.number().min(1, 'Debe ser mayor que 0'),
});

function ConfigConvenioDialog({ year, convenio, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <Formik
            initialValues={{
                horas_laborables: convenio?.horas_laborables ?? 0,
            }}
            enableReinitialize
            validationSchema={ConfigConvenioSchema}
            onSubmit={(values, actions) => {
                companiesProvider
                    .setConvenioByYear(year, values)
                    .then((result) => {
                        props.onClose();
                        onSave();
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            if (err.message instanceof Object) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    actions.setFieldError(field, errors.join('\n'));
                                }
                            } else {
                                snackbar.showMessage(err.message);
                            }
                        }
                    })
                    .finally(() => actions.setSubmitting(false));
            }}
        >
            {({ values, submitForm, setFieldValue, dirty, resetForm }) => {
                return (
                    <Form style={{ position: 'absolute' }}>
                        <DialogEditor
                            title={`Configurar convenio ${year}`}
                            onSave={submitForm}
                            className={classes.root}
                            {...props}
                        >
                            <Grid container className={classes.root} spacing={2} direction='column'>
                                <Grid item xs={12}>
                                    <TextField name='horas_laborables' type='number' />
                                </Grid>
                            </Grid>
                        </DialogEditor>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withButtonOpener(ConfigConvenioDialog);

ConfigConvenioDialog.propTypes = {
    year: PropTypes.number,
    convenio: PropTypes.object,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
