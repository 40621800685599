import Grid from '@material-ui/core/Grid';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { clientesProvider } from '../../api';
import { usePreferencias } from '../../AuthState';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DelegacionField from '../common/fields/DelegacionField';
import DialogForm from '../common/forms/DialogForm';
import ValidarDireccion from './direcciones/ValidarDireccion';

const ClientesSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    email: Yup.string().email('No es un email válido'),
});

function NewClienteDialog({ onSave, ...props }) {
    const snackbar = useSnackbar();

    const usarDelegaciones = usePreferencias('usar_delegaciones');

    return (
        <DialogForm
            title='Añadir cliente'
            maxWidth='md'
            FormikProps={{
                initialValues: {
                    nombre: '',
                    codigo: '',
                    nif: '',
                    email: '',
                    email_envios_list: '',
                    direccion: '',
                    direccion_secundaria: '',
                    telefono: '',
                    codigo_postal: '',
                    poblacion: '',
                    provincia: '',
                    persona_contacto: '',
                    activo: true,
                    google_place_id: null,
                    delegacion: null,
                },
                validationSchema: ClientesSchema,
                onSubmit: ({ delegacion, ...values }, { setSubmitting, setFieldError }) => {
                    clientesProvider
                        .save({ ...values, delegacion_id: delegacion?.id })
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField name='codigo' label='Codigo de cliente' fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField name='nif' label='NIF' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='persona_contacto' label='Persona de contacto' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='telefono' label='Telefono' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='email' label='Email' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='email_envios_list'
                            label='Email para envios de albaranes de servicio'
                            fullWidth
                            helperText='Puedes añadir varias direcciones separadas por comas'
                        />
                    </Grid>
                    <ValidarDireccion />
                    {/* <Grid item xs={6}> */}
                    {/*    <DireccionField /> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={6}> */}
                    {/*    <TextField */}
                    {/*        name='direccion_secundaria' */}
                    {/*        label='Direccion secundaria' */}
                    {/*        fullWidth */}
                    {/*        helperText='P.ej: bloque, escalera, piso, puerta, ...' */}
                    {/*    /> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={4}> */}
                    {/*    <TextField name='codigo_postal' label='Codigo postal' fullWidth /> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={4}> */}
                    {/*    <TextField name='poblacion' label='Poblacion' fullWidth /> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={4}> */}
                    {/*    <TextField name='provincia' label='Provincia' fullWidth /> */}
                    {/* </Grid> */}
                    <Grid item xs={4}>
                        <CheckboxWithLabel type='checkbox' name='activo' Label={{ label: 'Activo' }} />
                    </Grid>
                    {usarDelegaciones && (
                        <Grid item xs={8}>
                            <DelegacionField />
                        </Grid>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewClienteDialog);

NewClienteDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
