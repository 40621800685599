import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { fichajesProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DialogForm from '../common/forms/DialogForm';

const SolicitarRevisionFichajeSchema = Yup.object().shape({
    motivo: Yup.string().required('Requerido'),
});

function SolicitarRevisionFichajeDialog({ fichaje, onSave, ...props }) {
    const snackbar = useSnackbar();
    return (
        <DialogForm
            title='Solicitar revisión de fichaje'
            FormikProps={{
                initialValues: {
                    motivo: '',
                },
                validationSchema: SolicitarRevisionFichajeSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    setSubmitting(false);

                    fichajesProvider
                        .solicitarRevisionFichaje(fichaje.id, values.motivo)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name='motivo' label='Motivo de la modificación' fullWidth multiline minRows={3} />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(SolicitarRevisionFichajeDialog);

SolicitarRevisionFichajeDialog.propTypes = {
    fichaje: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
