import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { companiesProvider } from '../../../../../api';
import { withButtonOpener } from '../../../../../hooks/withButtonOpener';
import OperariosField from '../../../../common/fields/OperariosField';
import DialogForm from '../../../../common/forms/DialogForm';

const AsignarSchema = Yup.object().shape({
    operarios: Yup.array().min(1, 'Selecciona al menos un operario'),
});

function AsignarDireccionOperariosDialog({ id, onSave, ...props }) {
    const snackbar = useSnackbar();
    return (
        <DialogForm
            title='Asignar dirección a empleados'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    operarios: [],
                },
                validationSchema: AsignarSchema,
                enableReinitialize: true,
                onSubmit: ({ operarios, fecha }, { setSubmitting, setFieldError }) => {
                    const operarioIds = operarios.map((o) => o.id);

                    companiesProvider
                        .asignarOperariosDireccion(id, operarioIds)
                        .then(() => {
                            onSave && onSave();
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            snackbar.showMessage('Ha ocurrido un error');
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ errors }) => (
                <Grid container spacing={2} direction='column'>
                    <Grid item xs={12}>
                        <OperariosField addSelectAll label='Empleados' placeholder={null} />
                    </Grid>
                    {errors._schema && (
                        <Grid item xs={12}>
                            <Typography variant='subtitle2' color='error' style={{ whiteSpace: 'pre-wrap' }}>
                                {errors._schema}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

AsignarDireccionOperariosDialog.propTypes = {
    id: PropTypes.string.isRequired,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
};

export default withButtonOpener(AsignarDireccionOperariosDialog);
