import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { fichajesProvider } from '../../api';
import { formatFullDate, formatTime } from '../../utils';
import InfoField from '../common/fields/InfoField';
import DialogForm from '../common/forms/DialogForm';
import MarcajesField from './MarcajesField';

const FichajeSchema = Yup.object().shape({
    marcajes: Yup.array()
        .min(1, 'Tiene que haber al menos un marcaje')
        .of(
            Yup.object().shape({
                hora_entrada: Yup.date().typeError('Inválido').required('Requerido'),
                hora_salida: Yup.date()
                    .typeError('Inválido')
                    .required('Requerido')
                    .when('hora_entrada', (horaEntrada, schema) => {
                        return schema.test({
                            test: function (horaSalida) {
                                if (!horaEntrada || !horaSalida) {
                                    return true;
                                }
                                return horaEntrada < horaSalida;
                            },
                            message: 'La hora de salida tiene que ser posterior a la de entrada',
                        });
                    }),
            }),
        ),
    marcaje_pausa: Yup.object()
        .nullable()
        .shape({
            hora_entrada: Yup.date().typeError('Inválido').required('Requerido'),
            hora_salida: Yup.date()
                .typeError('Inválido')
                .required('Requerido')
                .when('hora_entrada', (horaEntrada, schema) => {
                    return schema.test({
                        test: function (horaSalida) {
                            if (!horaEntrada || !horaSalida) {
                                return true;
                            }
                            return horaEntrada < horaSalida;
                        },
                        message: 'La hora de salida tiene que ser posterior a la de entrada',
                    });
                }),
        }),
    motivo: Yup.string().required('Requerido'),
});

export function getMotivoEdicionFichajeFromSolicitud(solicitud) {
    return `El empleado dijo "${solicitud.motivo}"`;
}

export function EditFichajeDialog({ fichaje: originalFichaje, onSave, ...props }) {
    const snackbar = useSnackbar();

    const [fichaje, setFichaje] = useState(originalFichaje);
    useEffect(() => {
        setFichaje(originalFichaje);
    }, [originalFichaje]);

    return (
        <DialogForm
            title='Editar fichaje'
            FormikProps={{
                initialValues: {
                    fecha: new Date(fichaje.fecha),
                    operario_id: fichaje.operario_id,
                    marcajes: fichaje.marcajes,
                    marcaje_pausa: fichaje.marcaje_pausa,
                    motivo: fichaje.solicitud_revision
                        ? getMotivoEdicionFichajeFromSolicitud(fichaje.solicitud_revision)
                        : '',
                },
                enableReinitialize: true,
                validationSchema: FichajeSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    fichajesProvider
                        .save(values, fichaje.id)
                        .then((updatedFichaje) => {
                            setSubmitting(false);
                            onSave(updatedFichaje);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            } else {
                                snackbar.showMessage(err.toString());
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InfoField label='Operario' value={fichaje.operario} />
                        </Grid>
                        <Grid item xs={12}>
                            <InfoField label='Fecha' value={formatFullDate(fichaje.fecha)} />
                        </Grid>
                        {fichaje.solicitud_revision && (
                            <Grid item xs={12}>
                                <Alert severity='info' variant='filled'>
                                    El empleado solicitó la revision el{' '}
                                    {formatFullDate(fichaje.solicitud_revision.timestamp)} a las{' '}
                                    {formatTime(fichaje.solicitud_revision.timestamp)} con el motivo &quot;
                                    {fichaje.solicitud_revision.motivo}&quot;
                                </Alert>
                            </Grid>
                        )}
                        <MarcajesField
                            marcajes={values.marcajes}
                            marcajePausa={values.marcaje_pausa}
                            entradaLabel='Inicio pausa'
                            salidaLabel='Fin pausa'
                        />
                        <Grid item xs={12}>
                            <TextField
                                name='motivo'
                                label='Motivo de la modificación'
                                fullWidth
                                multiline
                                minRows={3}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

export function EditFichajeButton({ fichaje, onSave }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <IconButton onClick={() => setOpen(true)} style={{ padding: 6 }}>
                <EditIcon fontSize='small' />
            </IconButton>
            <EditFichajeDialog
                open={open}
                onSave={(res) => {
                    onSave(res);
                    setOpen(false);
                }}
                onClose={() => setOpen(false)}
                fichaje={fichaje}
            />
        </>
    );
}

EditFichajeDialog.propTypes = {
    fichaje: PropTypes.any,
    onSave: PropTypes.any,
};

EditFichajeButton.propTypes = {
    fichaje: PropTypes.any,
    onSave: PropTypes.any,
};
