import Grid from '@material-ui/core/Grid';
import { KeyboardTimePicker } from '../../../../common/fields/KeyboardDatePicker';
import InlineDialog from '../../../../common/forms/InlineDialog';

export default function HorarioNocturnoDialog() {
    return (
        <InlineDialog title='Horario nocturno'>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <KeyboardTimePicker label='Hora inicio' name='horario_nocturno.hora_inicio_as_fecha' fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <KeyboardTimePicker label='Hora fin' name='horario_nocturno.hora_fin_as_fecha' fullWidth />
                </Grid>
            </Grid>
        </InlineDialog>
    );
}
