import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { companiesProvider } from '../../../../../api';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { EditDireccionDialogButton } from '../../../../clientes/direcciones/EditDireccionDialog';
import { NewDireccionDialogButton } from '../../../../clientes/direcciones/NewDireccionDialog';
import Button from '../../../../common/Button';
import BaseListView, { withTableState } from '../../../../common/list/ListView';
import OperariosIcon from '../../../../icons/Operarios';
import AsignarDireccionOperariosDialog from './AsignarDireccionOperariosDialog';
import ConfigGeofichajesDialog from './ConfigGeofichajesDialog';
import DireccionesPorOperario from './DireccionesPorOperario';

const ListView = withTableState('direcciones', BaseListView);

export default function AjustesDirecciones({ classes }) {
    const fetchDataFn = useCallback(() => companiesProvider.getDirecciones(), []);
    const { data, fetchData: baseFetchData } = useFetchData(fetchDataFn);
    const direccionesPorOperarioRef = React.createRef();

    const snackbar = useSnackbar();

    const fetchData = useCallback(() => {
        baseFetchData();
        direccionesPorOperarioRef.current?.refresh();
    }, [baseFetchData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        companiesProvider
            .deleteDireccion(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Activo',
                accessor: 'activo',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? <DoneIcon /> : null),
                filter: 'equals',
                disableGlobalFilter: true,
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            deleteConfirmationText: 'borrar direccion',
            batchComponents: (
                <NewDireccionDialogButton
                    button={
                        <Button color='transparent' aria-label='Nueva direccion' startIcon={<AddIcon />}>
                            Nueva direccion
                        </Button>
                    }
                    onSave={() => {
                        fetchData();
                    }}
                />
            ),
        }),
        [],
    );

    return (
        <>
            <ConfigGeofichajesDialog />
            <Paper elevation={0}>
                <ListView
                    tableTitle={'Lista de ubicaciones para fichajes'}
                    basePath='/direcciones'
                    columns={columns}
                    data={data}
                    options={options}
                    onDelete={onDelete}
                    extraActions={(row) => (
                        <>
                            <EditDireccionDialogButton
                                direccion={row}
                                button={
                                    <Tooltip title='Editar direccion'>
                                        <IconButton>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                onSave={fetchData}
                            />
                            <AsignarDireccionOperariosDialog
                                id={row.id}
                                button={
                                    <Tooltip title='Asignar a empleados'>
                                        <IconButton>
                                            <OperariosIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                onSave={fetchData}
                            />
                        </>
                    )}
                />
            </Paper>
            <DireccionesPorOperario ref={direccionesPorOperarioRef} />
        </>
    );
}

AjustesDirecciones.propTypes = {
    classes: PropTypes.any,
};
