import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { startOfYear } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { operariosProvider } from '../../api';
import { createTiempo } from '../../api/tareas-functions';
import { formatTiempo } from '../../utils';
import RegularizacionesOperarioFechaDialog from '../regularizaciones_horas/RegularizacionesOperarioFechaDialog';
import FiltersFecha from './FiltersFecha';

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: '1px solid #eee',
            whiteSpace: 'nowrap',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
            '.MuiTableRow-head:nth-of-type(2) &.MuiTableCell-head.MuiTableCell-stickyHeader': {
                top: 33,
            },
            '&:not(:first-child)': {
                textAlign: 'right',
            },
        },
        footer: {
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
        sizeSmall: {
            '&:last-child': {
                padding: theme.spacing(0, 0.5, 0, 0),
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const TableRow = withStyles(
    (theme) => ({
        root: {
            '&:not(.empty):hover': {
                backgroundColor: theme.palette.neutral.grey3,
            },
        },
    }),
    { name: 'TableRow' },
)(MuiTableRow);

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        noLaborable: {
            '& td:not($diferencia)': {
                color: theme.palette.neutral.primary,
            },
        },
        diferencia: {
            color: theme.palette.error.main,
        },
        paper: {
            marginBottom: theme.spacing(3),
            flex: 1,
        },
        tableTitle: {
            margin: theme.spacing(1, 2),
            height: 32,
            display: 'flex',
            alignItems: 'center',
        },
        container: {
            maxHeight: 400,
        },
        stickyFooter: {
            '& tbody': {
                marginBottom: 33,
            },
            '& .MuiTableCell-footer': {
                left: 0,
                bottom: 0,
                position: 'sticky',
                zIndex: 2,
                backgroundColor: '#fafafa',
            },
        },
    }),
    { name: 'BolsaHoras' },
);

export default function BolsaHoras() {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(() => {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        return d;
    });
    const [loading, setLoading] = useState(false);
    const [resumen, setResumen] = useState([]);
    const [containerHeight, setContainerHeight] = useState(500);

    useEffect(() => {
        setResumen([]);

        if (!selectedDate) return;

        const fechaInicio = startOfYear(selectedDate);

        setLoading(true);
        operariosProvider.getBolsaHoras(selectedDate, fechaInicio).then((resumen) => {
            setLoading(false);
            setResumen(resumen);
        });
    }, [selectedDate]);

    const updateContainerHeight = useCallback((node) => {
        if (node !== null) {
            setContainerHeight(node.getBoundingClientRect().height - 32);
        }
    }, []);

    return (
        <>
            <div className={classes.header}>
                <Typography variant='h1' className={classes.title}>
                    Bolsa de horas
                </Typography>
            </div>
            <FiltersFecha setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
            <Paper elevation={0} className={classes.paper} ref={updateContainerHeight}>
                <TableContainer
                    className={classes.container}
                    style={{ minHeight: containerHeight, maxHeight: containerHeight }}
                >
                    <Table size='small' stickyHeader className={classes.stickyFooter}>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell colSpan={4}>Jornada del empleado</TableCell>
                                <TableCell colSpan={5}>Horas trabajadas por el empleado</TableCell>
                                <TableCell>Regulariz.</TableCell>
                                <TableCell>Saldo</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Empleado</TableCell>
                                <TableCell>H. Laborables</TableCell>
                                <TableCell>Vacaciones</TableCell>
                                <TableCell>Ausencias</TableCell>
                                <TableCell>H. Efectivas</TableCell>
                                <TableCell>H. Fichadas</TableCell>
                                <TableCell>Ordinarias</TableCell>
                                <TableCell>Sabado</TableCell>
                                <TableCell>Festivos</TableCell>
                                <TableCell>Nocturnas</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resumen.length === 0 && (
                                <TableRow className='empty'>
                                    <TableCell colSpan={12} align='center' style={{ height: containerHeight - 34 * 3 }}>
                                        {loading ? <CircularProgress /> : 'No hay datos'}
                                    </TableCell>
                                </TableRow>
                            )}
                            {resumen.map((resumenItem, i) => (
                                <TableRow key={i}>
                                    <TableCell>{resumenItem.operario}</TableCell>
                                    <TableCell>{formatTiempo(createTiempo(resumenItem.laborables.horario))}</TableCell>
                                    <TableCell>
                                        {formatTiempo(createTiempo(resumenItem.laborables.vacaciones))}
                                    </TableCell>
                                    <TableCell>
                                        {formatTiempo(createTiempo(resumenItem.laborables.ausencias))}
                                    </TableCell>
                                    <TableCell>
                                        {formatTiempo(createTiempo(resumenItem.laborables.efectivas))}
                                    </TableCell>
                                    <TableCell>
                                        {formatTiempo(createTiempo(resumenItem.total_minutos_fichajes))}
                                    </TableCell>
                                    <TableCell>
                                        {formatTiempo(createTiempo(resumenItem.desglose_minutos_fichajes.ORDINARIO))}
                                    </TableCell>
                                    <TableCell>
                                        {formatTiempo(createTiempo(resumenItem.desglose_minutos_fichajes.SABADO))}
                                    </TableCell>
                                    <TableCell>
                                        {formatTiempo(createTiempo(resumenItem.desglose_minutos_fichajes.FESTIVO))}
                                    </TableCell>
                                    <TableCell>
                                        {formatTiempo(createTiempo(resumenItem.desglose_minutos_fichajes.NOCTURNO))}
                                    </TableCell>
                                    <TableCell>
                                        {formatTiempo(createTiempo(resumenItem.total_minutos_regularizaciones, true))}
                                    </TableCell>
                                    <TableCell>{formatTiempo(createTiempo(resumenItem.saldo_minutos, true))}</TableCell>
                                    <TableCell>
                                        <RegularizacionesOperarioFechaDialog
                                            operario={{
                                                id: resumenItem.operario_id,
                                                nombre: resumenItem.operario,
                                            }}
                                            fecha={selectedDate}
                                            button={
                                                <IconButton>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}
