import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import PropTypes from 'prop-types';
import SelectFecha from '../solicitudes/SelectFecha';
import SelectOperario from './SelectOperario';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            color: '#8F95AF',
            padding: theme.spacing(2, 3),
            marginBottom: theme.spacing(2),
        },
        helper: {
            marginTop: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
        },
        button: {
            textTransform: 'none',
            textDecoration: 'underline',
            color: 'white',
            padding: '1px 4px',
        },
    }),
    { name: 'FiltersOperario' },
);

export default function FiltersOperario({
    onChangeOperario,
    defaultOperarioId,
    selectedDate,
    setSelectedDate,
    children,
    includeAdministrativos = false,
}) {
    const classes = useStyles();

    return (
        <Paper className={classes.root} elevation={0}>
            <SelectOperario
                defaultOperarioId={defaultOperarioId}
                onChange={(option) => {
                    onChangeOperario(option);
                }}
                includeAdministrativos={includeAdministrativos}
            />
            en el mes de
            <IconButton onClick={() => setSelectedDate(sub(selectedDate, { months: 1 }))}>
                <NavigateBeforeIcon />
            </IconButton>
            <SelectFecha fecha={selectedDate} onChange={setSelectedDate} />
            <IconButton onClick={() => setSelectedDate(add(selectedDate, { months: 1 }))}>
                <NavigateNextIcon />
            </IconButton>
            {children}
        </Paper>
    );
}

FiltersOperario.propTypes = {
    selectedDate: PropTypes.any,
    defaultOperarioId: PropTypes.any,
    onChangeOperario: PropTypes.any,
    setSelectedDate: PropTypes.any,
    children: PropTypes.any,
    includeAdministrativos: PropTypes.bool,
};
