import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WarningIcon from '@material-ui/icons/Warning';
import isSameDay from 'date-fns/isSameDay';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { operariosProvider } from '../../api';
import { createTiempo } from '../../api/tareas-functions';
import { formatDate, formatFullDate, formatTiempo } from '../../utils';
import Button from '../common/Button';
import { ToggleButton, ToggleButtonGroup } from '../common/ToggleButtonGroup';
import DownloadIcon from '../icons/Download';
import ExportarJornadaDialog from '../operarios/ExportarJornadaDialog';
import FiltersFecha from './FiltersFecha';
import FiltersOperario from './FiltersOperario';
import ResumenJornadaOperarioDialog from './ResumenJornadaOperarioDialog';

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: '1px solid #eee',
            whiteSpace: 'nowrap',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
            '.MuiTableRow-head:nth-of-type(2) &.MuiTableCell-head.MuiTableCell-stickyHeader': {
                top: 33,
            },
        },
        footer: {
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const TableRow = withStyles(
    (theme) => ({
        root: {
            '&:not(.empty):hover': {
                backgroundColor: theme.palette.neutral.grey3,
            },
        },
    }),
    { name: 'TableRow' },
)(MuiTableRow);

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        noLaborable: {
            '& td:not($diferencia)': {
                color: theme.palette.neutral.primary,
            },
        },
        diferencia: {
            color: theme.palette.error.main,
        },
        noOk: {
            color: theme.palette.error.main,
        },
        todoOk: {
            color: theme.palette.success.main,
        },
        paper: {
            marginBottom: theme.spacing(3),
            flex: 1,
        },
        tableTitle: {
            margin: theme.spacing(1, 2),
            height: 32,
            display: 'flex',
            alignItems: 'center',
        },
        container: {
            maxHeight: 400,
        },
        stickyFooter: {
            '& tbody': {
                marginBottom: 33,
            },
            '& .MuiTableCell-footer': {
                left: 0,
                bottom: 0,
                position: 'sticky',
                zIndex: 2,
                backgroundColor: '#fafafa',
            },
        },
        efectivas: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
        },
    }),
    { name: 'ControlJornada' },
);

const BY_OPERARIO = 'by-operario';
const BY_FECHA = 'by-fecha';

const views = [
    {
        id: BY_OPERARIO,
        label: 'Por operario',
    },
    {
        id: BY_FECHA,
        label: 'Por fechas',
    },
];

const STORAGE_KEY = 'ControlJornada-prefs';
const defaultPrefs = {
    currentView: BY_OPERARIO,
    operario: null,
};

export default function ControlJornada() {
    const classes = useStyles();
    const [currentView, setCurrentView] = useState(BY_OPERARIO);
    const [operario, setOperario] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState({
        start: new Date(),
        end: new Date(),
    });
    const [loading, setLoading] = useState(false);
    const [resumen, setResumen] = useState([]);
    const [containerHeight, setContainerHeight] = useState(500);
    const snackbar = useSnackbar();

    function loadPrefs() {
        const prefs = JSON.parse(localStorage.getItem(STORAGE_KEY)) || defaultPrefs;
        setCurrentView(prefs.currentView);
        setOperario(prefs.operario);
    }

    function updatePrefs() {
        localStorage.setItem(
            STORAGE_KEY,
            JSON.stringify({
                currentView,
                operario,
            }),
        );
    }
    useEffect(() => {
        loadPrefs();
    }, []);

    useEffect(() => {
        updatePrefs();
    }, [currentView, operario]);

    useEffect(() => {
        setResumen([]);

        if (currentView === BY_OPERARIO) {
            if (!operario) return;

            setLoading(true);
            operariosProvider
                .getControlJornadaOperario(operario.id, selectedDateRange.start)
                .then((resumen) => {
                    setResumen(
                        resumen.map((resumenFecha) => {
                            if (resumenFecha.fichaje)
                                resumenFecha.fichaje.diferencia =
                                    resumenFecha.fichaje.trabajados - resumenFecha.laborables.efectivas;

                            if (resumenFecha.jornada)
                                resumenFecha.jornada.diferencia =
                                    resumenFecha.jornada.trabajados - resumenFecha.laborables.efectivas;

                            return resumenFecha;
                        }),
                    );
                })
                .catch((err) => {
                    if (err.status === 404) {
                        setOperario(null);
                    } else {
                        snackbar.showMessage('Se ha producido un error al cargar los datos');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (currentView === BY_FECHA) {
            setLoading(true);
            operariosProvider
                .getControlJornadaFechas(selectedDateRange.start, selectedDateRange.end)
                .then((resumen) => {
                    setLoading(false);
                    setResumen(
                        resumen.map((resumenFecha) => {
                            if (resumenFecha.fichaje)
                                resumenFecha.fichaje.diferencia =
                                    resumenFecha.fichaje.trabajados - resumenFecha.laborables.efectivas;

                            if (resumenFecha.jornada)
                                resumenFecha.jornada.diferencia =
                                    resumenFecha.jornada.trabajados - resumenFecha.laborables.efectivas;

                            return resumenFecha;
                        }),
                    );
                });
        }
    }, [currentView, selectedDateRange, operario]);

    const updateContainerHeight = useCallback((node) => {
        if (node !== null) {
            setContainerHeight(node.getBoundingClientRect().height - 32);
        }
    }, []);

    const totales = useMemo(() => {
        const totales = {
            laborables: {
                horario: 0,
                vacaciones: 0,
                ausencias: 0,
                efectivas: 0,
            },
            fichaje: {
                trabajados: 0,
                diferencia: 0,
            },
            jornada: {
                trabajados: 0,
                diferencia: 0,
                revisados: 0,
                conAlbaran: 0,
                facturados: 0,
                total: 0,
                todosRevisados: true,
                todosConAlbaran: true,
                todosFacturados: true,
            },
        };

        resumen.forEach((resumenFecha) => {
            totales.laborables.horario += resumenFecha.laborables.horario;
            totales.laborables.vacaciones += resumenFecha.laborables.vacaciones;
            totales.laborables.ausencias += resumenFecha.laborables.ausencias;
            totales.laborables.efectivas += resumenFecha.laborables.efectivas;

            if (resumenFecha.fichaje) {
                totales.fichaje.trabajados += resumenFecha.fichaje.trabajados;
            }

            if (resumenFecha.jornada) {
                totales.jornada.trabajados += resumenFecha.jornada.trabajados;
                totales.jornada.revisados += resumenFecha.jornada.jornadas_revisadas;
                totales.jornada.conAlbaran += resumenFecha.jornada.jornadas_con_albaran;
                totales.jornada.facturados += resumenFecha.jornada.jornadas_facturadas;
                totales.jornada.total += resumenFecha.jornada.total_jornadas;
            }
        });
        totales.fichaje.diferencia = totales.fichaje.trabajados - totales.laborables.efectivas;
        totales.jornada.diferencia = totales.jornada.trabajados - totales.laborables.efectivas;
        totales.jornada.todosRevisados = totales.jornada.total === totales.jornada.revisados;
        totales.jornada.todosConAlbaran = totales.jornada.total === totales.jornada.conAlbaran;
        totales.jornada.todosFacturados = totales.jornada.total === totales.jornada.facturados;

        return totales;
    }, [resumen]);

    const fechaTitle = isSameDay(selectedDateRange.start, selectedDateRange.end)
        ? formatFullDate(selectedDateRange.start)
        : `${formatFullDate(selectedDateRange.start)} - ${formatFullDate(selectedDateRange.end)}`;

    return (
        <>
            <div className={classes.header}>
                <Typography variant='h1' className={classes.title}>
                    Control de la jornada laboral
                </Typography>

                <ToggleButtonGroup value={currentView} exclusive onChange={(ev, view) => setCurrentView(view)}>
                    {views.map(({ id, label }) => (
                        <ToggleButton key={id} value={id}>
                            {label}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                <ExportarJornadaDialog
                    button={
                        <Button
                            color='info'
                            aria-label='Exportar jornada'
                            startIcon={<DownloadIcon />}
                            style={{ marginLeft: 'auto' }}
                        >
                            Exportar jornada
                        </Button>
                    }
                />
            </div>
            {currentView === BY_OPERARIO ? (
                <FiltersOperario
                    onChangeOperario={(operario) => setOperario(operario ?? null)}
                    defaultOperarioId={operario?.id ?? null}
                    setSelectedDate={(date) => {
                        console.log(date);
                        setSelectedDateRange({ start: date, end: date });
                    }}
                    selectedDate={selectedDateRange.start}
                />
            ) : (
                <FiltersFecha setSelectedDateRange={setSelectedDateRange} selectedDateRange={selectedDateRange} />
            )}
            <Paper elevation={0} className={classes.paper} ref={updateContainerHeight}>
                <Typography variant='h6' className={classes.tableTitle}>
                    {currentView === BY_OPERARIO
                        ? operario
                            ? `Operario: ${operario.nombre}`
                            : 'Selecciona un operario para ver sus datos'
                        : `Fecha: ${fechaTitle}`}
                </Typography>
                <TableContainer
                    className={classes.container}
                    style={{ minHeight: containerHeight, maxHeight: containerHeight }}
                >
                    <Table size='small' stickyHeader className={classes.stickyFooter}>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell colSpan={4}>Jornada del operario</TableCell>
                                <TableCell colSpan={2}>Control horario</TableCell>
                                <TableCell colSpan={2}>Productividad</TableCell>
                                <TableCell colSpan={3}>Partes de trabajo</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{currentView === BY_OPERARIO ? 'Fecha' : 'Operario'}</TableCell>
                                <TableCell>H. Laborables</TableCell>
                                <TableCell>Vacaciones</TableCell>
                                <TableCell>Ausencias</TableCell>
                                <TableCell>H. Efectivas</TableCell>
                                <TableCell>H. Fichadas</TableCell>
                                <TableCell>Diferencia</TableCell>
                                <TableCell>H. Trabajadas</TableCell>
                                <TableCell>Diferencia</TableCell>
                                <TableCell>Revisados</TableCell>
                                <TableCell>En Albarán</TableCell>
                                <TableCell>Facturados</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resumen.length === 0 && (
                                <TableRow className='empty'>
                                    <TableCell colSpan={13} align='center' style={{ height: containerHeight - 34 * 3 }}>
                                        {loading ? <CircularProgress /> : 'No hay datos'}
                                    </TableCell>
                                </TableRow>
                            )}
                            {resumen.map((resumenFecha, i) => {
                                let motivoSinRegistro = 'Sin registro';

                                if (resumenFecha.laborables.efectivas === 0) {
                                    if (resumenFecha.laborables.horario === null) {
                                        motivoSinRegistro = 'No laborable';
                                    } else if (resumenFecha.laborables.horario === 0) {
                                        motivoSinRegistro = 'Sin horarios';
                                    } else if (resumenFecha.laborables.vacaciones > 0) {
                                        motivoSinRegistro = 'Vacaciones';
                                    } else if (resumenFecha.laborables.ausencias > 0) {
                                        motivoSinRegistro = 'Ausencia';
                                    }
                                }

                                const jornadaStats = {
                                    total: 0,
                                    revisados: 0,
                                    conAlbaran: 0,
                                    facturados: 0,
                                    todosRevisados: true,
                                    todosConAlbaran: true,
                                    todosFacturados: true,
                                };

                                if (resumenFecha.jornada) {
                                    jornadaStats.total = resumenFecha.jornada.total_jornadas;
                                    jornadaStats.revisados = resumenFecha.jornada.jornadas_revisadas;
                                    jornadaStats.conAlbaran = resumenFecha.jornada.jornadas_con_albaran;
                                    jornadaStats.facturados = resumenFecha.jornada.jornadas_facturadas;
                                    jornadaStats.todosRevisados = jornadaStats.total === jornadaStats.revisados;
                                    jornadaStats.todosConAlbaran = jornadaStats.total === jornadaStats.conAlbaran;
                                    jornadaStats.todosFacturados = jornadaStats.total === jornadaStats.facturados;
                                }

                                return (
                                    <TableRow
                                        key={i}
                                        className={resumenFecha.laborables.efectivas === 0 ? classes.noLaborable : null}
                                    >
                                        <TableCell>
                                            {currentView === BY_OPERARIO && resumenFecha.fecha
                                                ? formatDate(resumenFecha.fecha)
                                                : resumenFecha.operario}
                                        </TableCell>
                                        <TableCell>
                                            {resumenFecha.laborables.horario !== null
                                                ? formatTiempo(createTiempo(resumenFecha.laborables.horario))
                                                : 'No laborable'}
                                        </TableCell>
                                        <TableCell>
                                            {resumenFecha.laborables.horario !== null
                                                ? formatTiempo(createTiempo(resumenFecha.laborables.vacaciones))
                                                : 'No laborable'}
                                        </TableCell>
                                        <TableCell>
                                            {resumenFecha.laborables.horario !== null
                                                ? formatTiempo(createTiempo(resumenFecha.laborables.ausencias))
                                                : 'No laborable'}
                                        </TableCell>
                                        <TableCell>
                                            {resumenFecha.laborables.horario !== null ? (
                                                <div className={classes.efectivas}>
                                                    {formatTiempo(createTiempo(resumenFecha.laborables.efectivas))}
                                                    {resumenFecha.laborables.ausencias_no_retribuidas > 0 && (
                                                        <Tooltip
                                                            arrow
                                                            title={`Ausencias no retribuidas: ${formatTiempo(
                                                                createTiempo(
                                                                    resumenFecha.laborables.ausencias_no_retribuidas,
                                                                ),
                                                            )}`}
                                                        >
                                                            <WarningIcon fontSize='small' />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            ) : (
                                                'No laborable'
                                            )}
                                        </TableCell>
                                        {resumenFecha.fichaje ? (
                                            <>
                                                <TableCell>
                                                    {formatTiempo(createTiempo(resumenFecha.fichaje.trabajados))}
                                                </TableCell>
                                                <TableCell>
                                                    {formatTiempo(createTiempo(resumenFecha.fichaje.diferencia, true))}
                                                </TableCell>
                                            </>
                                        ) : (
                                            <TableCell colSpan={2}>{motivoSinRegistro}</TableCell>
                                        )}
                                        {resumenFecha.jornada ? (
                                            <>
                                                <TableCell
                                                    className={
                                                        resumenFecha.jornada.diferencia < 0
                                                            ? classes.noOk
                                                            : classes.todoOk
                                                    }
                                                >
                                                    {formatTiempo(createTiempo(resumenFecha.jornada.trabajados))}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        resumenFecha.jornada.diferencia < 0
                                                            ? classes.noOk
                                                            : classes.todoOk
                                                    }
                                                >
                                                    {formatTiempo(createTiempo(resumenFecha.jornada.diferencia, true))}
                                                </TableCell>
                                            </>
                                        ) : (
                                            <TableCell colSpan={2}>{motivoSinRegistro}</TableCell>
                                        )}
                                        <TableCell
                                            className={jornadaStats.todosRevisados ? classes.todoOk : classes.noOk}
                                        >{`${jornadaStats.revisados} / ${jornadaStats.total}`}</TableCell>
                                        <TableCell
                                            className={jornadaStats.todosConAlbaran ? classes.todoOk : classes.noOk}
                                        >{`${jornadaStats.conAlbaran} / ${jornadaStats.total}`}</TableCell>
                                        <TableCell
                                            className={jornadaStats.todosFacturados ? classes.todoOk : classes.noOk}
                                        >{`${jornadaStats.facturados} / ${jornadaStats.total}`}</TableCell>
                                        <TableCell>
                                            <ResumenJornadaOperarioDialog
                                                id={
                                                    currentView === BY_OPERARIO
                                                        ? operario?.id
                                                        : resumenFecha.operario_id
                                                }
                                                fechaRange={
                                                    currentView === BY_OPERARIO && resumenFecha.fecha
                                                        ? { start: resumenFecha.fecha, end: resumenFecha.fecha }
                                                        : selectedDateRange
                                                }
                                                operario={
                                                    currentView === BY_OPERARIO
                                                        ? operario?.nombre
                                                        : resumenFecha.operario
                                                }
                                                button={
                                                    <IconButton>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>Totales</TableCell>
                                <TableCell>{formatTiempo(createTiempo(totales.laborables.horario))}</TableCell>
                                <TableCell>{formatTiempo(createTiempo(totales.laborables.vacaciones))}</TableCell>
                                <TableCell>{formatTiempo(createTiempo(totales.laborables.ausencias))}</TableCell>
                                <TableCell>{formatTiempo(createTiempo(totales.laborables.efectivas))}</TableCell>

                                <TableCell>{formatTiempo(createTiempo(totales.fichaje.trabajados))}</TableCell>
                                <TableCell>{formatTiempo(createTiempo(totales.fichaje.diferencia, true))}</TableCell>

                                <TableCell>{formatTiempo(createTiempo(totales.jornada.trabajados))}</TableCell>
                                <TableCell>{formatTiempo(createTiempo(totales.jornada.diferencia, true))}</TableCell>
                                <TableCell
                                    className={
                                        totales.jornada.total > 0 &&
                                        (totales.jornada.todosRevisados ? classes.todoOk : classes.noOk)
                                    }
                                >{`${totales.jornada.revisados} / ${totales.jornada.total}`}</TableCell>
                                <TableCell
                                    className={
                                        totales.jornada.total > 0 &&
                                        (totales.jornada.todosConAlbaran ? classes.todoOk : classes.noOk)
                                    }
                                >{`${totales.jornada.conAlbaran} / ${totales.jornada.total}`}</TableCell>
                                <TableCell
                                    className={
                                        totales.jornada.total > 0 &&
                                        (totales.jornada.todosFacturados ? classes.todoOk : classes.noOk)
                                    }
                                >{`${totales.jornada.facturados} / ${totales.jornada.total}`}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}
