import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import FileUploader from '../../../common/fields/FileUploader';
import { SwitchWithLabel } from '../../../common/fields/Switch';

export default function AjustesGenerales({ classes, setLogoFile, setFirmaFile, values }) {
    return (
        <>
            <Paper elevation={0}>
                <Typography className={classes.title}>Ajustes generales</Typography>
                <Grid container spacing={4} className={classes.content}>
                    <Grid item xs={12}>
                        <FileUploader
                            id='upload-logo-file'
                            label='Logo de la empresa'
                            value={values.logo_url}
                            file={values.logo_file}
                            setFile={setLogoFile}
                            accept='image/*'
                        />
                        {values.logo_url && (
                            <Field name='borrar_logo' component={SwitchWithLabel} label='Eliminar logo existente' />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <FileUploader
                            id='upload-firma_empresa-file'
                            label='Firma de la empresa'
                            value={values.firma_empresa_url}
                            file={values.firma_empresa_file}
                            setFile={setFirmaFile}
                            accept='image/*'
                        />
                        {values.firma_empresa_url && (
                            <Field
                                name='borrar_firma_empresa'
                                component={SwitchWithLabel}
                                label='Eliminar firma existente'
                            />
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

AjustesGenerales.propTypes = {
    values: PropTypes.any,
    classes: PropTypes.any,
    setLogoFile: PropTypes.any,
    setFirmaFile: PropTypes.any,
};
