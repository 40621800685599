import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { clientesProvider, companiesProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogForm from '../../common/forms/DialogForm';
import { Tab, Tabs } from '../../common/Tabs';
import ValidarDireccion from './ValidarDireccion';

const DireccionSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    coords: Yup.lazy((value, { parent }) =>
        !parent.direccion || parent.direccion.length === 0 ? Yup.string().required('Requerido') : Yup.string(),
    ),
    direccion: Yup.lazy((value, { parent }) =>
        !parent.coords || parent.coords.length === 0 ? Yup.string().required('Requerido') : Yup.string(),
    ),
    google_place_id: Yup.lazy((value, { parent }) =>
        !parent.coords || parent.coords.length === 0 ? Yup.string().required('Requerido') : Yup.string(),
    ),
});

export function openDirectionsInMaps(direccion, placeId) {
    const params = {
        destination: direccion,
    };

    if (placeId) params.destination_place_id = placeId;

    const url = `https://www.google.com/maps/dir/?api=1&${new URLSearchParams(params).toString()}`;
    window.open(url, '_blank');
}

export function openSearchInMaps(direccion, placeId) {
    const params = {
        query: direccion,
    };

    if (placeId) params.destination_place_id = placeId;

    const url = `https://www.google.com/maps/search/?api=1&${new URLSearchParams(params).toString()}`;
    window.open(url, '_blank');
}

const useStyles = makeStyles(
    (theme) => ({
        tabs: {
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'NewDireccionDialog' },
);

export const MAPS = 'maps';
export const COORDS_GPS = 'coords_gps';

export function NewDireccionDialog({ onSave, clienteId, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [tipoDireccion, setTipoDireccion] = useState(MAPS);

    return (
        <DialogForm
            title='Añadir dirección'
            maxWidth='md'
            FormikProps={{
                initialValues: {
                    nombre: '',
                    telefono: '',
                    direccion: '',
                    direccion_secundaria: '',
                    codigo_postal: '',
                    poblacion: '',
                    provincia: '',
                    notas: '',
                    coords: '',
                },
                validationSchema: DireccionSchema,
                onSubmit: ({ cliente, ...values }, { setSubmitting, setFieldError }) => {
                    const promise = clienteId
                        ? clientesProvider.actionOnId(clienteId, 'direcciones', values)
                        : companiesProvider.createDireccion(values);

                    promise
                        .then((result) => {
                            props.onClose && props.onClose();
                            onSave(result);
                            snackbar.showMessage('Se ha guardado la dirección con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                name='nombre'
                                label='Identificador'
                                fullWidth
                                helperText='P.ej: principal, casa, local en Gran Via, obra en Tarragona, ...'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField name='telefono' label='Telefono' fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs
                                disableGutters
                                value={tipoDireccion}
                                onChange={(ev, newValue) => setTipoDireccion(newValue)}
                                aria-label='Tipo de direccion'
                                classes={{
                                    flexContainer: classes.tabs,
                                }}
                            >
                                <Tab label='Google Maps' value={MAPS} />
                                <Tab label='Coordenadas GPS' value={COORDS_GPS} />
                            </Tabs>
                        </Grid>
                        {tipoDireccion === MAPS && <ValidarDireccion />}
                        {tipoDireccion === COORDS_GPS && (
                            <Grid item xs={6}>
                                <TextField name='coords' label='Coordenadas' fullWidth />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                name='notas'
                                label='Notas'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                                placeholder='Información extra como nombre de contacto, horario de apertura de una tienda, etc... El operario podrá ver esta información en su móvil.'
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

NewDireccionDialog.propTypes = {
    clienteId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};

export const NewDireccionDialogButton = withButtonOpener(NewDireccionDialog);
