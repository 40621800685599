import { useEffect, useState } from 'react';
import { companiesProvider } from '../../../api';
import MasterDetailsView from '../../common/forms/MasterDetailsView/MasterDetailsView';
import AprendeDetails from './AprendeDetails';
import AprendeItem from './AprendeItem';
import CentroAyuda from './CentroAyuda/CentroAyuda';
import PrimerosPasos from './PrimerosPasos/PrimerosPasos';

export default function AprendeTab() {
    const [primerosPasosList, setPrimerosPasosList] = useState(null);

    useEffect(() => {
        companiesProvider.getPrimerosPasos().then(setPrimerosPasosList);
    }, []);

    const aprendeSections = [];

    if (primerosPasosList !== null && primerosPasosList.length > 0) {
        aprendeSections.push(
            ...primerosPasosList.map((primerosPasos) => ({
                id: primerosPasos.id,
                label: primerosPasos.nombre,
                content: (
                    <PrimerosPasos
                        id={primerosPasos.id}
                        nombre={primerosPasos.nombre}
                        initialPrimerosPasos={primerosPasos.steps}
                    />
                ),
            })),
        );
    }

    aprendeSections.push({
        id: 'centro-de-ayuda',
        label: 'Centro de ayuda',
        content: <CentroAyuda />,
    });

    return (
        <MasterDetailsView
            title='Aprende'
            masterComponent={AprendeItem}
            detailsComponent={AprendeDetails}
            items={aprendeSections}
        />
    );
}
