import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LockIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/Settings';
import { useSnackbar } from 'material-ui-snackbar-provider';
import * as PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { fichajesProvider } from '../../api';
import { createTiempo } from '../../api/tareas-functions';
import { formatTiempo } from '../../utils';
import Button from '../common/Button';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import { ToggleButton, ToggleButtonGroup } from '../common/ToggleButtonGroup';
import FiltersOperario from '../control_jornada/FiltersOperario';
import CertificadoFichajesIcon from '../icons/CertificadoFichajes';
import DownloadIcon from '../icons/Download';
import LockOpenIcon from '../icons/LockOpen';
import ConfigurarRevisionDialog from './ConfigurarRevisionDialog';
import FichajeHistoricoList from './FichajeHistoricoList';
import { useResumenOperario } from './FichajesContext';
import { RevisionFichaje } from './RevisionFichaje';
import { TableCell, TableRow } from './TableComponents';

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        diferencia: {
            color: theme.palette.error.main,
        },
        paper: {
            marginBottom: theme.spacing(3),
            flex: 1,
        },
        tableTitle: {
            margin: theme.spacing(1, 2),
            height: 32,
            display: 'flex',
            alignItems: 'center',
        },
        tableTitleActions: {
            marginLeft: 'auto',
            display: 'flex',
        },
        container: {
            maxHeight: 400,
        },
        stickyFooter: {
            '& tbody': {
                marginBottom: 33,
            },
            '& .MuiTableCell-footer': {
                left: 0,
                bottom: 0,
                position: 'sticky',
                zIndex: 2,
                backgroundColor: '#fafafa',
            },
        },
        marcajeWrapperHeader: {
            display: 'flex',
            gap: theme.spacing(1),
            '& span': {
                width: 66,
            },
        },
        filterActions: {
            marginLeft: 'auto',
        },
        estadoChips: {
            display: 'flex',
            gap: `${theme.spacing(0.5)}px`,
        },
        expandCell: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
    { name: 'RevisionFichajes' },
);

export const STORAGE_KEY = 'RevisionFichajes-prefs';
export const defaultPrefs = {
    operario: null,
    selectedDate: null,
};

const FICHAJES = 'fichajes';
const HISTORICO = 'historico';

function RevisionFichajesFooter({ expanded, maxMarcajes, totales, bloqueado }) {
    return (
        <TableFooter>
            <TableRow>
                <TableCell>Totales</TableCell>
                {expanded && (
                    <>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.horario))}</TableCell>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.vacaciones))}</TableCell>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.ausencias))}</TableCell>
                    </>
                )}
                <TableCell>{formatTiempo(createTiempo(totales.laborables.efectivas))}</TableCell>
                <TableCell>{formatTiempo(createTiempo(totales.laborables.fichadas))}</TableCell>
                <TableCell>{formatTiempo(createTiempo(totales.laborables.diferencia, true))}</TableCell>
                {maxMarcajes > 0 && <TableCell colSpan={maxMarcajes} />}
                <TableCell colSpan={bloqueado ? 2 : 3}></TableCell>
            </TableRow>
        </TableFooter>
    );
}

RevisionFichajesFooter.propTypes = {
    totales: PropTypes.shape({
        laborables: PropTypes.shape({
            fichadas: PropTypes.number,
            horario: PropTypes.number,
            efectivas: PropTypes.number,
            vacaciones: PropTypes.number,
            ausencias: PropTypes.number,
            diferencia: PropTypes.number,
        }),
    }),
    maxMarcajes: PropTypes.any,
    expanded: PropTypes.bool,
    bloqueado: PropTypes.bool,
};

export default function RevisionFichajes() {
    const classes = useStyles();
    const [view, setView] = useState(FICHAJES);

    return (
        <>
            <div className={classes.header}>
                <Typography variant='h1' className={classes.title}>
                    Revisión de fichajes
                </Typography>
                <ToggleButtonGroup value={view} exclusive onChange={(ev, view) => setView(view)}>
                    <ToggleButton value={FICHAJES}>Fichajes</ToggleButton>
                    <ToggleButton value={HISTORICO}>Histórico de cambios</ToggleButton>
                </ToggleButtonGroup>
            </div>

            {view === FICHAJES && <VistaFichajes />}
            {view === HISTORICO && <FichajeHistoricoList />}
        </>
    );
}

function VistaFichajes() {
    const snackbar = useSnackbar();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [selectedDate, setSelectedDate] = useState(() => {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        return d;
    });
    const [operario, setOperario] = useState(null);

    function onResumenLoadError(err) {
        if (err.status === 404) {
            setOperario(null);
        } else {
            snackbar.showMessage('Se ha producido un error al cargar los datos');
        }
    }

    const {
        resumen,
        reloadData,
        onUpdateMarcaje,
        onUpdateMotivo,
        onUpdateFichaje,
        onAddMarcaje,
        onSaveFichajes,
        updateContainerHeight,
        containerHeight,
        totales,
        maxMarcajes,
        diferenciaMin,
        diferenciaMax,
        loading,
        hasErrors,
        hasTouched,
    } = useResumenOperario(operario, selectedDate, onResumenLoadError);

    const mesBloqueado = useMemo(() => {
        const validRows = resumen.filter((row) => row.fichaje.id);

        return validRows.length > 0 && validRows.every((row) => row.fichaje.bloqueado);
    }, [resumen]);
    const pastMonthSelected = useMemo(() => {
        const today = new Date();
        return selectedDate.getMonth() < today.getMonth() || selectedDate.getFullYear() < today.getFullYear();
    }, [selectedDate]);

    const canBloquear =
        !mesBloqueado &&
        pastMonthSelected &&
        resumen.length > 0 &&
        resumen.every((row) => !row.fichaje.id || (row.fichaje.admin_verificado && row.fichaje.operario_verificado));

    function loadPrefs() {
        const prefs = JSON.parse(localStorage.getItem(STORAGE_KEY)) || defaultPrefs;
        setOperario(prefs.operario);
        if (prefs.selectedDate) setSelectedDate(new Date(prefs.selectedDate));
    }

    function updatePrefs() {
        localStorage.setItem(
            STORAGE_KEY,
            JSON.stringify({
                operario,
                selectedDate,
            }),
        );
    }
    useEffect(() => {
        loadPrefs();
    }, []);

    useEffect(() => {
        updatePrefs();
    }, [operario, selectedDate]);

    const exportarTodos = () => {
        fichajesProvider
            .exportar(selectedDate)
            .then(async (data) => {
                const uri = window.URL.createObjectURL(await data.blob());

                const a = document.createElement('a');
                a.style = { display: 'none' };
                a.href = uri;
                a.download = data.headers.get('Content-Disposition').split('filename=')[1];
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(uri);
                document.body.removeChild(a);
            })
            .catch((err) => {
                // snackbar.showMessage('Ha ocurrido un error durante la descarga');
                console.error(err);
            });
    };

    const exportar = () => {
        fichajesProvider
            .exportar(selectedDate, operario?.id)
            .then(async (data) => {
                const uri = window.URL.createObjectURL(await data.blob());

                const a = document.createElement('a');
                a.style = { display: 'none' };
                a.href = uri;
                a.download = data.headers.get('Content-Disposition').split('filename=')[1];
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(uri);
                document.body.removeChild(a);
            })
            .catch((err) => {
                // snackbar.showMessage('Ha ocurrido un error durante la descarga');
                console.error(err);
            });
    };

    return (
        <>
            <FiltersOperario
                onChangeOperario={(operario) => setOperario(operario ?? null)}
                defaultOperarioId={operario?.id ?? null}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                includeAdministrativos
            >
                <div className={classes.filterActions}>
                    {hasTouched && (
                        <Button color='primary' onClick={reloadData}>
                            Descartar cambios
                        </Button>
                    )}
                    <Tooltip title={hasErrors ? 'Soluciona los errores antes de guardar' : ''} arrow>
                        <span>
                            <Button
                                color='info'
                                onClick={() => onSaveFichajes(snackbar)}
                                disabled={hasErrors || !hasTouched}
                            >
                                Guardar
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title='Descargar XLS con los fichajes de todos los empleados'>
                        <IconButton style={{ marginLeft: 'auto' }} onClick={exportarTodos}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                    <ConfigurarRevisionDialog
                        button={
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        }
                    />
                </div>
            </FiltersOperario>
            <Paper elevation={0} className={classes.paper} ref={updateContainerHeight}>
                <div className={classes.tableTitle}>
                    <Typography variant='h6'>
                        {operario ? `Empleado: ${operario.nombre}` : 'Selecciona un empleado para ver sus datos'}
                    </Typography>

                    <div className={classes.tableTitleActions}>
                        <IconButton onClick={exportar}>
                            <DownloadIcon />
                        </IconButton>

                        {mesBloqueado ? (
                            <ButtonDialog
                                button={
                                    <IconButton>
                                        <LockOpenIcon />
                                    </IconButton>
                                }
                                tooltip={
                                    'Si desbloqueas los fichajes del mes, se perderá la firma que pueda haber hecho el empleado'
                                }
                                dialogTitle='Desbloquear hoja de horas'
                                dialogText={[
                                    'Si desbloqueas los fichajes del mes, se perderá la firma que pueda haber hecho el empleado.',
                                    '¿Deseas continuar?',
                                ]}
                                confirmationText='desbloquear'
                                onConfirm={() => {
                                    fichajesProvider.desbloquear(operario?.id, selectedDate).then(reloadData);
                                }}
                            />
                        ) : (
                            <ButtonDialog
                                button={
                                    <IconButton disabled={!canBloquear}>
                                        <LockIcon />
                                    </IconButton>
                                }
                                tooltip={
                                    !canBloquear
                                        ? 'Sólo se puede bloquear un mes que ya ha pasado y que tiene todos sus fichajes aprobados'
                                        : ''
                                }
                                dialogTitle='Bloquear hoja de horas'
                                dialogText={[
                                    'Despues de bloquear la hoja de horas de este mes ya no se podran editar los fichajes de este empleado. ',
                                    '¿Deseas continuar?',
                                ]}
                                confirmationText='bloquear'
                                onConfirm={() => {
                                    fichajesProvider.bloquear(operario?.id, selectedDate).then(reloadData);
                                }}
                            />
                        )}

                        <Tooltip title='Descargar certificado jornada laboral'>
                            <IconButton
                                disabled={hasErrors}
                                aria-label='Descargar certificado'
                                onClick={() =>
                                    window.open(fichajesProvider.getCertificadoURL(selectedDate, operario?.id))
                                }
                            >
                                <CertificadoFichajesIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <TableContainer
                    className={classes.container}
                    style={{ minHeight: containerHeight, maxHeight: containerHeight }}
                >
                    <Table size='small' stickyHeader className={classes.stickyFooter}>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell colSpan={expanded ? 6 : 3}>
                                    <div className={classes.expandCell}>
                                        Jornada del empleado{' '}
                                        <IconButton size='small' onClick={() => setExpanded((expanded) => !expanded)}>
                                            {expanded ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                        </IconButton>
                                    </div>
                                </TableCell>
                                {new Array(maxMarcajes).fill(null).map((_, i) => (
                                    <TableCell key={i}>Marcaje {i + 1}</TableCell>
                                ))}
                                <TableCell colSpan={4}></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className='fixed'>Fecha</TableCell>
                                {expanded && (
                                    <>
                                        <TableCell className='fixed'>Laborables</TableCell>
                                        <TableCell className='fixed'>Vacaciones</TableCell>
                                        <TableCell className='fixed'>Ausencias</TableCell>
                                    </>
                                )}
                                <TableCell className='fixed'>Efectivas</TableCell>
                                <TableCell className='fixed'>Fichadas</TableCell>
                                <TableCell className='fixed'>Diferencia</TableCell>
                                {new Array(maxMarcajes).fill(null).map((_, i) => (
                                    <TableCell key={i}>
                                        <div className={classes.marcajeWrapperHeader}>
                                            <span>Entrada</span>
                                            <span>Salida</span>
                                        </div>
                                    </TableCell>
                                ))}
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Estado</TableCell>
                                {!mesBloqueado && <TableCell>Acciones</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resumen.length === 0 && (
                                <TableRow className='empty'>
                                    <TableCell
                                        colSpan={(expanded ? 11 : 8) - (mesBloqueado ? 1 : 0)}
                                        align='center'
                                        style={{ height: containerHeight - 34 * 3 }}
                                    >
                                        {loading ? <CircularProgress /> : 'No hay datos'}
                                    </TableCell>
                                </TableRow>
                            )}
                            {resumen.map((resumenFecha, i) => (
                                <RevisionFichaje
                                    resumenFecha={resumenFecha}
                                    maxMarcajes={maxMarcajes}
                                    key={i}
                                    onAddMarcaje={() => onAddMarcaje(i)}
                                    diferenciaMin={diferenciaMin}
                                    diferenciaMax={diferenciaMax}
                                    expanded={expanded}
                                    onUpdateMarcaje={(...args) => onUpdateMarcaje(i, ...args)}
                                    onUpdateMotivo={(motivo) => onUpdateMotivo(i, motivo)}
                                    onUpdateFichaje={(updatedFichaje) => {
                                        onUpdateFichaje(updatedFichaje);
                                        snackbar.showMessage('Fichajes guardados correctamente');
                                    }}
                                    onDeleteFichaje={() => reloadData()}
                                    bloqueado={mesBloqueado}
                                />
                            ))}
                        </TableBody>
                        <RevisionFichajesFooter
                            bloqueado={mesBloqueado}
                            expanded={expanded}
                            totales={totales}
                            maxMarcajes={maxMarcajes}
                        />
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

VistaFichajes.propTypes = {
    operario: PropTypes.object,
    selectedDate: PropTypes.any,
};
