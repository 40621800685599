import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers';
import isValid from 'date-fns/isValid';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { companiesProvider, operariosProvider } from '../../../../../api';
import { useFetchData } from '../../../../../hooks/useFetchData';

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: '1px solid #eee',
            whiteSpace: 'nowrap',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
            '.MuiTableRow-head:nth-of-type(2) &.MuiTableCell-head.MuiTableCell-stickyHeader': {
                top: 33,
            },
        },
        footer: {
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const TableRow = withStyles(
    (theme) => ({
        root: {
            '&:not(.empty):hover': {
                backgroundColor: theme.palette.neutral.grey3,
            },
        },
    }),
    { name: 'TableRow' },
)(MuiTableRow);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            padding: theme.spacing(0, 2),
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(2)}px`,
        },
        datePicker: {
            width: 150,
        },
    }),
    { name: 'HorariosPorOperario' },
);

export default forwardRef(function HorariosPorOperario(props, ref) {
    const classes = useStyles();
    const [currentDate, setCurrentDate] = useState(new Date());
    const fetchDataFn = useCallback(() => companiesProvider.getHorariosEmpresa(currentDate), [currentDate]);
    const { data: horarios, fetchData } = useFetchData(fetchDataFn);

    const [operarios, setOperarios] = useState([]);

    useEffect(() => {
        if (ref === null) return;

        ref.current = {
            refresh: fetchData,
        };
    }, [ref, fetchData]);

    useEffect(() => {
        operariosProvider.getOnlyActiveAsOptions().then(setOperarios);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Paper elevation={0} className={classes.root}>
            <Typography variant='body1' component='div' className={classes.title}>
                Horarios por empleado en fecha
                <MuiKeyboardDatePicker
                    clearable
                    autoOk={true}
                    className={classes.datePicker}
                    value={currentDate}
                    format='dd/MM/yyyy'
                    onChange={(date) => {
                        if (!isValid(date)) return;
                        setCurrentDate(date);
                    }}
                />
            </Typography>

            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {horarios.map((horario) => (
                            <TableCell key={horario.id}>{horario.nombre}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {operarios.map((operario) => (
                        <TableRow key={operario.id}>
                            <TableCell>{operario.nombre}</TableCell>
                            {horarios.map((horario) => {
                                return (
                                    <TableCell key={horario.id}>
                                        {horario.operarios.find((o) => o.id === operario.id) ? (
                                            <DoneIcon fontSize='small' />
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
});
