import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { operariosProvider } from '../../api';

const Autocomplete = withStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    inputRoot: {
        '& $input:first-child': {
            padding: theme.spacing(0.5),
        },
    },
    input: {},
    option: {
        display: 'flex',
        alignItems: 'center',
        '& > span': {
            flex: 1,
        },
        '& > svg': {
            fontSize: 18,
        },
    },
}))(MuiAutocomplete);

export default function SelectOperario({ defaultOperarioId, onChange, includeAdministrativos = false }) {
    const [operariosOptions, setOperariosOptions] = useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState(null);

    useEffect(() => {
        operariosProvider.getOnlyActiveAsOptions(includeAdministrativos).then((operarios) => {
            setOperariosOptions(operarios);
        });
    }, []);

    useEffect(() => {
        if (operariosOptions.length === 0) return;

        if (!defaultOperarioId) {
            setValue(null);
            onChange(null);
            return;
            // setValue(operariosOptions[0]);
            // onChange(operariosOptions[0]);
        }

        setValue(operariosOptions.find((op) => op.id === defaultOperarioId) || operariosOptions[0]);
    }, [defaultOperarioId, operariosOptions]);

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                onChange(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            getOptionLabel={(option) => option.nombre}
            renderOption={(option) => (
                <React.Fragment>
                    <span>{option.nombre}</span>
                </React.Fragment>
            )}
            getOptionSelected={(option, value) => option.id === value.id}
            id='controllable-states-demo'
            options={operariosOptions}
            style={{ width: 300 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label=''
                    variant='outlined'
                    placeholder={`Selecciona un ${includeAdministrativos ? 'empleado' : 'operario'}`}
                />
            )}
        />
    );
}

SelectOperario.propTypes = {
    defaultOperarioId: PropTypes.any,
    onChange: PropTypes.any,
    includeAdministrativos: PropTypes.bool,
};
