import { FormControl } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import { addWeeks } from 'date-fns';
import endOfWeek from 'date-fns/endOfWeek';
import esES from 'date-fns/locale/es';
import startOfWeek from 'date-fns/startOfWeek';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { format, formatISODate } from '../../utils';

const Select = withStyles((theme) => ({
    root: {
        paddingTop: 13,
        paddingBottom: 13,
    },
}))(MuiSelect);

export const SelectControl = ({ options, variant = 'outlined', ...props }) => (
    <FormControl variant={variant} style={{ margin: '0 8px' }}>
        <Select {...props}>
            {options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                    {label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

const yearOptions = new Array(new Date().getFullYear() - 2020 + 2)
    .fill(null)
    .map((x, i) => ({ label: 2020 + i, value: 2020 + i }));

export default function SelectWeek({ fecha, onChange }) {
    const handleChange = (event) => {
        event.persist();

        const newDate = new Date(fecha);
        if (event.target.name === 'week') {
            onChange(new Date(event.target.value));
            return;
        } else if (event.target.name === 'year') newDate.setFullYear(event.target.value);

        onChange(newDate);
    };

    const [weekOptions, setWeekOptions] = useState([]);

    const currentYear = fecha.getFullYear();
    useEffect(() => {
        let start = startOfWeek(new Date(currentYear, 0, 1), { locale: esES });
        const weekOptions = [];
        while (start.getFullYear() <= currentYear) {
            const end = endOfWeek(start, { locale: esES });

            weekOptions.push({
                label: `Semana ${weekOptions.length + 1}: ${format(start, 'dd MMM yyyy')} - ${format(
                    end,
                    'dd MMM yyyy',
                )}`,
                value: formatISODate(start),
            });

            start = addWeeks(start, 1);
        }

        setWeekOptions(weekOptions);
    }, [currentYear]);

    return (
        <>
            <SelectControl name='week' options={weekOptions} value={formatISODate(fecha)} onChange={handleChange} />
            de
            <SelectControl name='year' options={yearOptions} value={fecha.getFullYear()} onChange={handleChange} />
        </>
    );
}

SelectWeek.propTypes = {
    fecha: PropTypes.object,
    onChange: PropTypes.any,
};

SelectControl.propTypes = {
    options: PropTypes.array.isRequired,
    variant: PropTypes.string,
};
