import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { regularizacionesHorasProvider } from '../../api';
import { tipoLabels } from '../../api/regularizaciones_horas';
import { createTiempo } from '../../api/tareas-functions';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { formatDate, formatFullDate, formatTiempo } from '../../utils';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import DialogEditor from '../common/forms/DialogEditor';
import EditRegularizacionDialog from './EditRegularizacionDialog';
import NewRegularizacionDialog from './NewRegularizacionDialog';

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: '1px solid #eee',
            whiteSpace: 'nowrap',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            maxWidth: 650,
        },
        dialogTitle: {
            flex: 1,
        },
    }),
    { name: 'RegularizacionesOperarioFechaDialog' },
);

function RegularizacionesOperarioFechaDialog({ fecha, operario, ...props }) {
    const classes = useStyles();
    const [regularizaciones, setRegularizaciones] = useState([]);

    const open = props.open;

    const fetchData = useCallback(
        () => regularizacionesHorasProvider.getByOperarioFecha(operario.id, fecha).then(setRegularizaciones),
        [fecha, operario.id],
    );

    useEffect(() => {
        if (!open) return;

        fetchData();
    }, [fetchData, open]);

    const tiempoTotal = useMemo(
        () => createTiempo(regularizaciones.reduce((total, item) => total + item.minutos, 0)),
        [regularizaciones],
    );

    return (
        <DialogEditor
            title={
                <>
                    <Typography variant='h6' className={classes.dialogTitle}>
                        Regularizaciones para {operario.nombre} hasta el {formatFullDate(fecha)}
                    </Typography>

                    <NewRegularizacionDialog
                        button={
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                        }
                        operario={operario}
                        onSave={fetchData}
                    />
                </>
            }
            disableBodyGutters
            maxWidth='md'
            cancelButtonText='Cerrar'
            {...props}
        >
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Tiempo</TableCell>
                        <TableCell>Periodo efecto</TableCell>
                        <TableCell>Motivo</TableCell>
                        <TableCell>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {regularizaciones.map((regularizacion, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell>{formatDate(regularizacion.fecha_creacion)}</TableCell>
                                <TableCell>{tipoLabels[regularizacion.tipo]}</TableCell>
                                <TableCell>{formatTiempo(createTiempo(regularizacion.minutos))}</TableCell>
                                <TableCell>
                                    {regularizacion.tipo === 'EXTRAS'
                                        ? 'N/A'
                                        : `${formatDate(regularizacion.fecha_inicio)} - ${formatDate(
                                              regularizacion.fecha_fin,
                                          )}`}
                                </TableCell>
                                <TableCell>{regularizacion.motivo}</TableCell>
                                <TableCell>
                                    <EditRegularizacionDialog
                                        regularizacion={regularizacion}
                                        button={
                                            <IconButton>
                                                <EditIcon />
                                            </IconButton>
                                        }
                                        onSave={fetchData}
                                    />
                                    <DeleteDialog
                                        text='¿Estás seguro que quieres eliminar esta regularización?'
                                        onConfirm={(ev) => {
                                            regularizacionesHorasProvider.delete(regularizacion.id).then(fetchData);
                                        }}
                                        button={
                                            <IconButton>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={2}>Total</TableCell>
                        <TableCell>{formatTiempo(tiempoTotal)}</TableCell>
                        <TableCell colSpan={2}></TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </DialogEditor>
    );
}

export default withButtonOpener(RegularizacionesOperarioFechaDialog);

RegularizacionesOperarioFechaDialog.propTypes = {
    fecha: PropTypes.any,
    id: PropTypes.any,
    open: PropTypes.any,
    operario: PropTypes.any,
};
