import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CopyIcon from '@material-ui/icons/FileCopy';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { isFuture } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { fichajesProvider, operariosProvider } from '../../../../api';
import { IS_LOCALHOST } from '../../../../api/api-config';
import useAuthState from '../../../../AuthState';
import { format, formatFullDate, formatISODate } from '../../../../utils';
import Button from '../../../common/Button';
import { ButtonDialog } from '../../../common/dialogs/ButtonDialog';
import MonthDaySelector from '../../../common/MonthDaySelector';
import SolicitarRevisionFichajeDialog from '../../SolicitarRevisionFichajeDialog';
import ResumenDia from './ResumenDia';
import ResumenMes from './ResumenMes';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            padding: theme.spacing(2),
        },
        volverButton: {
            alignSelf: 'flex-start',
            transform: 'translateX(-12px)',
        },
        title: {
            textAlign: 'center',
            '& .MuiTypography-root:first-of-type': {
                fontWeight: 'bold',
            },
        },
        content: {
            padding: theme.spacing(0, 2, 2, 2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: `${theme.spacing(2)}px`,
        },
        leyenda: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(0.25)}px`,
            width: '100%',
        },
        leyendaItem: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            '&>div': {
                borderRadius: 4,
                width: 18,
                height: 18,
            },
            '& .MuiTypography-root': {
                fontWeight: 400,
                lineHeight: '20px',
            },
        },
        resumen: {
            width: '100%',
            border: `1px solid ${theme.palette.neutral.grey1}`,
            '&>*': {
                padding: theme.spacing(2),
            },
            '&>*:not(:first-of-type)': {
                borderTop: `1px solid ${theme.palette.neutral.grey1}`,
            },
        },
        resumenTitle: {
            fontWeight: '600',
        },
        qrCode: {
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(2, 0),
        },
    }),
    { name: 'HistorialFichajes' },
);

const calendarColors = {
    day: '#163E88',
    noLaborable: '#4C60FE',
    pendiente: '#E94F4F',
    revisado: '#F3AC3D',
    verificado: '#30BDA1',
};

const calendarLegend = {
    day: 'Laborable, sin registro',
    noLaborable: 'No laborable',
    pendiente: 'Modificación solicitada',
    revisado: 'Falta la aprobación de administración',
    verificado: 'Fichaje aprobado',
};

const useFichajeStyles = makeStyles(
    (theme) => ({
        noLaborable: {
            '& button': {
                backgroundColor: `${calendarColors.noLaborable} !important`,
            },
        },
        pendiente: {
            '& button': {
                backgroundColor: `${calendarColors.pendiente} !important`,
            },
        },
        revisado: {
            '& button': {
                backgroundColor: `${calendarColors.revisado} !important`,
            },
        },
        verificado: {
            '& button': {
                backgroundColor: `${calendarColors.verificado} !important`,
            },
        },
    }),
    { name: 'Fichaje' },
);

export function getClassNameFromFichaje(fecha, estado, noLaborable) {
    if (!isFuture(new Date(fecha)) && estado) {
        return estado.toLowerCase();
    }
    return noLaborable ? 'noLaborable' : null;
}

export function HistorialFichajes({ onVolver }) {
    const classes = useStyles();
    const fichajeClasses = useFichajeStyles();
    const [fichajes, setFichajes] = useState([]);
    const [noLaborables, setNoLaborables] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [selectedFichaje, setSelectedFichaje] = useState(null);
    const year = format(fecha, 'Y');
    const month = format(fecha, 'M');

    const {
        userInfo: { user_id: operarioId },
    } = useAuthState();

    useEffect(() => {
        if (operarioId) {
            fichajesProvider.getAll(`revision/${operarioId}/${year}/${month}`).then(setFichajes);
        }
    }, [operarioId, year, month]);

    useEffect(() => {
        operariosProvider.getAll(`no_laborables/${year}`).then(setNoLaborables);
    }, [year]);

    const classesByFecha = useMemo(() => {
        const fichajesEstado = Object.fromEntries(
            fichajes
                .filter((f) => f.fichaje)
                .map(({ fecha, fichaje: { admin_verificado: administracion, operario_verificado: operario } }) => {
                    let estado = null;
                    if (!operario) {
                        estado = 'pendiente';
                    } else if (!administracion) {
                        estado = 'revisado';
                    } else {
                        estado = 'verificado';
                    }
                    return [formatISODate(fecha), estado];
                }),
        );

        const days = [];
        const date = new Date(year, month - 1, 1);
        while (date.getMonth() === month - 1) {
            const currentDay = formatISODate(date);
            const className = getClassNameFromFichaje(
                currentDay,
                fichajesEstado[currentDay],
                noLaborables.includes(currentDay),
            );

            days.push([currentDay, className ? fichajeClasses[className] : null]);
            date.setDate(date.getDate() + 1);
        }

        return Object.fromEntries(days);
    }, [fichajes, noLaborables]);

    const fichajesValidos = fichajes.map((f) => f.fichaje).filter(Boolean);

    const mesBloqueado = fichajesValidos.length > 0 && fichajesValidos.every((f) => f.bloqueado);
    const mesFirmado = fichajesValidos.length > 0 && fichajesValidos.every((f) => f.firmado);

    function onUpdated(fichajesVerificados) {
        setFichajes((fichajes) =>
            fichajes.map((f) => {
                if (f.fichaje === null) return f;

                const fichajeVerificado = fichajesVerificados.find((fv) => fv.id === f.fichaje.id);
                if (!fichajeVerificado) return f;

                if (selectedFichaje !== null && selectedFichaje.id === f.fichaje.id) {
                    setSelectedFichaje(fichajeVerificado);
                }

                return {
                    ...f,
                    fichaje: {
                        ...f.fichaje,
                        ...fichajeVerificado,
                    },
                };
            }),
        );
    }

    function objectToBase64(obj) {
        const json = JSON.stringify(obj);
        return Buffer.from(json).toString('base64');
    }
    const { userInfo } = useAuthState();

    const baseUrl = IS_LOCALHOST ? 'http://localhost:3001' : 'https://operarios.labory.app';
    const token = objectToBase64({ operario_id: userInfo.user_id, nombre: userInfo.name, year, month });
    const firmaUrl = `${baseUrl}/firma/${token}`;

    return (
        <div className={classes.root}>
            <Button
                color='primary'
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={() => {
                    if (selectedFichaje) {
                        setSelectedFichaje(null);
                    } else {
                        onVolver();
                    }
                }}
                className={classes.volverButton}
            >
                Volver
            </Button>
            <div className={classes.content}>
                <div className={classes.title}>
                    <Typography>{selectedFichaje ? 'Fichaje del' : 'Historial mensual de fichajes'}</Typography>
                    <Typography>
                        {selectedFichaje
                            ? formatFullDate(selectedFichaje.fecha)
                            : 'Selecciona un día para ver los detalles'}
                    </Typography>
                </div>
                <MonthDaySelector
                    showMonthSelector
                    showSelectedDay={false}
                    fecha={fecha}
                    classesByFecha={classesByFecha}
                    onDateChange={(date) => {
                        setFecha(date);

                        const dia = fichajes.find((f) => f.fichaje && f.fichaje.fecha === formatISODate(date));
                        if (dia && dia.fichaje) {
                            setSelectedFichaje(dia.fichaje);
                        }
                    }}
                    onMonthChange={(date) => {
                        setFecha(date);
                        setSelectedFichaje(null);
                    }}
                />
                <div className={classes.leyenda}>
                    {Object.entries(calendarColors).map(([key, color]) => (
                        <div className={classes.leyendaItem} key={key}>
                            <div style={{ backgroundColor: color }} />
                            <Typography variant='subtitle2'>{calendarLegend[key]}</Typography>
                        </div>
                    ))}
                </div>
                <Paper elevation={0} className={classes.resumen}>
                    <Typography variant='body2' component='div' className={classes.resumenTitle}>
                        {selectedFichaje
                            ? formatFullDate(selectedFichaje.fecha)
                            : `Resúmen de ${format(fecha, 'MMMM yyyy')}`}
                    </Typography>

                    {selectedFichaje && <ResumenDia fichaje={selectedFichaje} />}
                    {!selectedFichaje && <ResumenMes fichajes={fichajes} />}
                </Paper>
                {selectedFichaje && !selectedFichaje.bloqueado && (
                    <>
                        {!selectedFichaje.operario_verificado && !selectedFichaje.admin_verificado ? (
                            <Button color='info' size='big' disabled fullWidth>
                                Fichaje pendiente de revisión
                            </Button>
                        ) : (
                            <SolicitarRevisionFichajeDialog
                                fichaje={selectedFichaje}
                                onUpdate={onUpdated}
                                operarioId={operarioId}
                                button={
                                    <Button
                                        size='big'
                                        color={
                                            !selectedFichaje.operario_verificado && selectedFichaje.admin_verificado
                                                ? 'outlined'
                                                : 'info'
                                        }
                                        outline={
                                            !selectedFichaje.operario_verificado && selectedFichaje.admin_verificado
                                        }
                                        rounded
                                        fullWidth
                                    >
                                        Solicitar revisión del fichaje
                                    </Button>
                                }
                            />
                        )}
                    </>
                )}
                {mesBloqueado && !mesFirmado && (
                    <ButtonDialog
                        dialogTitle='Firmar hoja de horas con codigo QR'
                        closeButtonText='Cerrar'
                        dialogContent={
                            <>
                                <Typography>
                                    Para firmar la hoja de horas del mes, escanea el siguiente código QR.
                                </Typography>
                                <div className={classes.qrCode}>
                                    <QRCode value={firmaUrl} size={256} />
                                </div>
                                <Typography variant='body2'>
                                    ¿No puedes escanear el código QR?
                                    <Button
                                        color='outlined'
                                        size='small'
                                        onClick={() => {
                                            navigator.clipboard.writeText(firmaUrl);
                                        }}
                                        startIcon={<CopyIcon fontSize='small' />}
                                    >
                                        Copiar URL
                                    </Button>
                                </Typography>
                                <Typography variant='body2'></Typography>
                            </>
                        }
                        button={
                            <Button size='big' color='info' rounded fullWidth onClick={() => {}}>
                                Generar QR para firmar el mes
                            </Button>
                        }
                    />
                )}
            </div>
        </div>
    );
}

HistorialFichajes.propTypes = {
    onVolver: PropTypes.func.isRequired,
};
