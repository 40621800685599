import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { proyectosProvider } from '../../../api';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsView from '../../common/forms/MasterDetailsView/MasterDetailsView';
import AvanceObraDetails from './AvanceObraDetails';
import AvanceObraItem from './AvanceObraItem';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'AvancesObraList' },
);

export default function AvancesObraList() {
    const classes = useStyles();
    const { data: proyecto, fetchData, updateData } = useEditFormData();
    const { id: proyectoId } = useParams();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (proyecto.avances_obra !== null) return;

        proyectosProvider.getAll(`${proyectoId}/avances-obra`).then((avances) => {
            updateData((proyecto) => ({
                ...proyecto,
                avances_obra: avances,
            }));
        });
    }, [proyecto, proyectoId]);

    if (proyecto.avances_obra === null) return null;

    return (
        <MasterDetailsView
            title='Avances de obra'
            masterComponent={AvanceObraItem}
            masterExtraProps={(avanceObra, index) => ({
                onDelete: () => {
                    proyectosProvider
                        .deleteAvanceObra(avanceObra.id)
                        .then(fetchData)
                        .catch((err) => {
                            snackbar.showMessage(err.message);
                        });
                },
            })}
            detailsComponent={AvanceObraDetails}
            items={proyecto.avances_obra}
            isEmpty={proyecto.avances_obra.length === 0}
            emptyComponent={
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Avances de obra</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Aquí verás los avances del proyecto.
                            {proyecto.avances_obra.length === 0 &&
                                'Para poder crear avances, primero tienes que añadir un presupuesto al proyecto.'}
                        </Typography>
                        <Button
                            color='info'
                            onClick={() => {
                                proyectosProvider
                                    .actionOnId(proyectoId, 'avances-obra')
                                    .then(fetchData)
                                    .catch((err) => {
                                        snackbar.showMessage(err.message);
                                    });
                            }}
                            disabled={proyecto.presupuestos.length === 0}
                        >
                            Crear avance de obra
                        </Button>
                    </div>
                </Paper>
            }
            actions={
                <IconButton
                    onClick={() => {
                        proyectosProvider
                            .actionOnId(proyectoId, 'avances-obra')
                            .then(fetchData)
                            .catch((err) => {
                                snackbar.showMessage(err.message);
                            });
                    }}
                >
                    <AddIcon />
                </IconButton>
            }
        />
    );
}

AvancesObraList.propTypes = {};
